@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    padding: 0;
    color: var(--paragraph-color);
    overflow-x: hidden;
    font-family: "Poppins" , var(--body-font)   ;
    line-height: var(--line-height30);
    font-size: var(--body-font-size);
    background: #fff;
    font-weight: 500;
}

h1 {
    font-size: 75px;
    line-height: 1.2333333333333333;
}

h2 {
    font-size: 43px;
    line-height: 1.2380952380952381;
}

h3 {
    font-size: 38px;
    line-height: 1.3833333333333333;
}

h4 {
    font-size: 24px;
    line-height: 1.3380952380952381;
}
h5 {
    font-size: 20px;
    line-height: 1.3380952380952381;
}
h6 {
    font-size: 16px;
    line-height: 1.2380952380952381;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-weight: 600;
    font-family: var(--heading-font);
}

p {
    color: var(--paragraph-color);
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin-bottom: 10px;
}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.4s;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
}
a:hover {
    color: var(--main-color);
}


/*input and button type focus outline disable*/

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
}



.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid{
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.no-gutter>[class^="col-"]{
  padding-left: 0;
  padding-right: 0;
}
.no-gutter[class^="col-"]{
    padding-left: 0;
    padding-right: 0;
  }

