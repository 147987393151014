.events-container{
    display: flex;
    justify-content:space-around;
    align-items: center;

    .event-section{
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: column;   
        .event-box{
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: column; 
          
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.4) 5px 5px, rgba(0, 0, 0, 0.3) 10px 10px, rgba(0, 0, 0, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
            .event-img{
                width: 100%;
                text-align: center;
                border-radius: 10px;
                }
        }
   
}
}

.event-box:hover{
    background-color: red;

}


