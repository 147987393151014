
.single-service-inner-3 {
    background: #fff;
    box-shadow: $box-shadow;
    position: relative;
    transition: 0.4s;
    margin-bottom: 30px;
    .thumb {
      display: inline-block;
      border-radius: 50%;
      border: 1px solid orangered;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s;
      .thumb-inner {
        background: #fff;
        height: 70px;
        width: 70px;
        line-height: 68px;
        border-radius: 50%;
        box-shadow: 0px 0px 4px rgba(19, 16, 34, 0.15);
        margin: 7px;
      }
    }
    .details {
      p {
        transition: 0.4s;
      }
    }
    &.single-service-inner-3-left {
      padding: 40px 50px 40px 30px;
      .thumb {
        right: -40px;
      }
    }
    &.single-service-inner-3-right {
      padding: 40px 30px 40px 50px;
      .thumb {
        left: -40px;
      }
    }
    &:hover {
      background: orangered;
      .thumb {
        border: 1px solid #fff;
      }
      .details {
        h5 {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }
  }
  .bg-blue-right {
    background: rgba(175, 80, 80, 0.1);
  }
  .service-middle-section {
    padding: 30px 30px 0 30px;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  