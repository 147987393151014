.thumb{

}
.thumbimg{
width:100%;
height: auto;
padding: 10px;
border-radius: 5px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.thumbimg1{
    width: 300px;
    height: auto;
    margin: 10px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-bottom: 6px solid orange;    
}