/*--------------------------------------------------
    ## All responsive style
---------------------------------------------------*/
@media all and (min-width: 1600px) {

  .single-service-inner-3.single-service-inner-3-left {
    padding: 43px 70px 43px 40px;
  }
  .single-service-inner-3.single-service-inner-3-right {
    padding: 43px 40px 43px 70px;
  }
}


@media all and (max-width: 1599px) {
 
}



@media all and (max-width: 991px) {
  h2{
    font-size:28px ;
  }
  h3{
    font-size: 24px;
  }
    .imgdiv{
        width: 100%;
    }
    .imgContent{
        width: 100%;
        margin: 50px 0px;  
        text-align: center; 
    }
    .common-btn{
        align-items: center;
    }
   
  .single-service-inner-3 {
    margin-right: 40px;
    padding: 25px 60px 25px 25px !important;
  }
  .single-service-inner-3.single-service-inner-3-right .thumb {
    left: auto;
    right: -40px;
  }
}



@media all and (max-width: 767px) {
  


}



@media all and (max-width: 575px) {

}

@media all and (min-width: 1300px) {
    .custom-container {
      max-width: 1250px;
    }
    .common-padding{   
    padding:0 80px 0;

    }
  }

  @media all and (min-width: 2000px) {
    .contact-inner-2 {
      max-width: 1920px;
      margin: 0 auto;
    }
    .common-padding{
        padding: 0;
        margin: 0 80px;
    }
  }

  @media all and (min-width: 1800px) {
    .custom-container {
      max-width: 1720px;
      padding: 80px 0;
    } 
    .common-padding{
        padding: 0 80px 0;
        margin: 0;
    }
  }
  