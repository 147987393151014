.common-btn{
  height: 45px;
  padding: 10px 20px;
  background-color: #ff7b00;
  border-radius: 5px;
  border: 1px solid #ff7b00;
}

.common-btn:hover{
  background-color: orangered;
  border: 1px solid orangered;
}

/********* social-media *********/
.social-media {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 2;
    li {
      display: inline-block;
      margin: 0 2px;
      a {
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 5px;
        display: inline-block;
        background: #333f4d;
        color: #fff;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: var(--main-color);
          color: #fff;
        }
        &.facebook {
          color: $facebook;
          border-color: $facebook;
        }
        &.twitter {
          color: $twitter;
          border-color: $twitter;
        }
        &.instagram {
          color: $instagram;
          border-color: $instagram;
        }
        &.youtube {
          color: $youtube;
          border-color: $youtube;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }


.pt-120{
  padding-top: 120px;
}

.pt-100{
  padding-top: 100px;
}
.pt-80{
  padding-top: 80px;
}
.pt-50{
  padding-top: 50px;
}

.pb-120{
  padding-bottom: 120px;
}

.pb-100{
  padding-bottom: 100px;
}
.pb-80{
  padding-bottom: 80px;
}
.pb-50{
  padding-bottom: 50px;
}

@media all and (max-width: 991px){
  
.pt-120{
  padding-top: 60px;
}

.pt-100{
  padding-top: 50px;
}
.pt-80{
  padding-top: 40px;
}
.pt-50{
  padding-top: 25px;
}

.pb-120{
  padding-bottom: 60px;
}

.pb-100{
  padding-bottom: 40px;
}
.pb-80{
  padding-bottom: 40px;
}
.pb-50{
  padding-bottom: 25px;
}

}