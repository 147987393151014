
/************ single-inpur-inner ************/
.single-input-inner {
    margin-bottom: 20px;
    input {
        width: 100%;
        height: 50px;
        border: 0 !important;
        padding: 0 18px;
        border-radius: 6px;
        border: 1px solid #246bfd !important;
        font-weight: 300;
        &::placeholder {
            color: #adadad;
        }
    }
    .PhoneInputfield{
        width: 100%;
        height: 50px;
        border: 0 !important;
        padding: 0;
        border-radius: 6px;
        border: 1px solid rgba(234, 225, 214, 0.7) !important;
        // border: 1px solid #246bfd !important;
        font-weight: 300;
        &::placeholder {
            color: #adadad;
        }
        }
    textarea {
        width: 100%;
        border: 0 !important;
        height: 140px;
        border-radius: 6px;
        padding: 14px 18px;
        font-weight: 300;
        border: 1px solid #246bfd !important;
        &::placeholder {
            color: #adadad;
        }
        // .input-icon {
        //     position: absolute;
        //     right: 10px;
        //     pointer-events: none; /* Ensures the icon doesn't block input interaction */
        //   }
    }
    .single-select {
        width: 100%;
        height: 50px;
        line-height: 50px;
        border: 0 !important;
        border-radius: 6px;
        padding: 0 18px;
        margin-bottom: 20px;
        .list {
            width: 100%;
        }
    }
    &.style-border {
        input {
            border: 1px solid rgba(234, 225, 214, 0.7) !important;
            &::placeholder {
                color: #adadad;
            }
        }
        textarea {
            border: 1px solid rgba(234, 225, 214, 0.7) !important;
            background: transparent;
            &::placeholder {
                color: #adadad;
            }
        }
        .single-select {
            border: 1px solid rgba(234, 225, 214, 0.7) !important;
            color: #adadad;
        }
    }
    &.style-border-bottom {
        input {
            border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
            background: transparent;
            padding: 0;
            &::placeholder {
                color: #adadad;
            }
        }
        textarea {
            border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
            &::placeholder {
                color: #adadad;
            }
        }
        .single-select {
            border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
            background: transparent;
            color: #adadad;
        }
    }
    &.style-bg {
        input {
            background: #F7FAFC;
            &::placeholder {
                color: #3F517E;
            }
        }
        textarea {
            background: #F7FAFC;
            &::placeholder {
                color: #3F517E;
            }
        }
        .single-select {
            background: #F7FAFC;
            color: #3F517E;
        }
    }
    &.style-bg-none {
        input {
            background: transparent;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }
        textarea {
            background: transparent;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }
        .single-select {
            background: transparent;
            color: #fff;
        }
    }
}


.contact-form-inner {
    padding: 0 30px 30px;
    border-radius: 7px;
    overflow: hidden;
    background-size: cover;
    .single-input-inner.style-border-bottom {
        input {
            border-bottom: 1px solid #fff !important;
            &::placeholder {
                color: #fff;
            }
        }
    }
}
